// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'qa-na',
  production: false,
  region: 'NA',
  appUrl: 'https://asset-profile.qa.zonarsystems.net',

  apiBase: {
    nonProdUrl: 'https://zonar-nonprod-qa.apigee.net',
    url: 'https://api-qa.zonarsystems.net'
  },

  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net/core/v1'
  },

  userPreferenceApiBase: {
    url: 'https://api-qa.zonarsystems.net/alert/v1'
  },

  tripApiBase: {
    url: 'https://trip-api.qa.zonarsystems.net'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'qa',

  auth: {
    clientID: 'vOkYryJzWL9IaXPM4DsDv1cGeMVfPkpw',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: 'faa8aaf8-e4fb-4cc1-86ad-ac843f4843f6'
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/',
    key: '47e63a49-4477-47c2-4ea6-0f032e1785d5'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app
  datadog: {
    applicationId: '<DATADOG_APPLICATION_ID>',
    clientToken: '<DATADOG_CLIENT_TOKEN>',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'qa'
  },

  i18n: {
    supportedLanguages: [
      'en',
      'en-US',
      'de-DE',
      'en-GB',
      'it',
      'fr-FR',
      'de',
      'es-ES'
    ],
    defaultLanguage: 'en-US'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
